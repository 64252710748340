import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import LocationListGroup from "../components/locations/location-list-group";
import Seo from "../components/seo"; // import Photo from "../images/safeway/jill-mcginness.jpg";

import Logo from "../images/fred-meyer/logo-400.png";
import HowToDonateSection from "../sections/how-to-donate-section";
import QuoteSection from "../sections/quote-section";
import AboutSection from "../sections/text-section";
import VideoSection from "../sections/video-section";
import Layout from "./layouts/layout";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
  <Seo title="Fred Meyer" mdxType="Seo" />
  <QuoteSection logo={Logo} logoUrl={"https://fredmeyer.com"} photo={false} text={"We are very excited to be part of the KGW Great Toy Drive. At Fred Meyer we see the need for people struggling in the communities that we serve every day, and we want to help bring some joy to local families this holiday season. The Toy Drive provides a great opportunity for all of us to help make a difference for people right here in the community that we call home."} name={"Jeffery Temple"} title={"Director of Corporate Affairs"} mdxType="QuoteSection" />
  <LocationListGroup lists={[{
        title: false,
        url: "https://kgw-sales-partners.s3.us-west-2.amazonaws.com/fred-meyer-locations.json",
        id: "fred-meyer-locations",
        note: true
      }]} headline="Buy and Drop Off a Toy Today!" map={false} mdxType="LocationListGroup" />
  <HowToDonateSection mdxType="HowToDonateSection" />
  <VideoSection videos={[{
        file: "fred-meyer-30.mp4"
      }, {
        file: "regence-60.mp4"
      }, {
        file: "iq-toy-bot-30.mp4"
      }, {
        file: "toyota-duplicator-30.mp4"
      }, {
        file: "iq-surprise-30.mp4"
      }, {
        file: "toyota-surprise-30.mp4"
      }]} mdxType="VideoSection" />
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      